import {useCallback, useEffect, useMemo, useState} from 'react';
import {useMediaQuery, useTheme} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {modalActions} from 'state/ui/slices/modal';
const transitionDuration = 350;

const useUiModal = () => {
  const dispatch = useDispatch();

  const modal = useSelector(state => state.ui.modal);

  // Logic to determine whether modal should display fullScreen on small devices
  const [fullScreen, setFullscreen] = useState(false);
  const theme = useTheme();
  const queryResult = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(() => setFullscreen(queryResult), [queryResult]);

  // Default dialog options to pass into modals
  const defaultDialog = useMemo(() => ({
    disablePortal: true,
    fullScreen,
    fullWidth: true,
    maxWidth: 'sm',
    scroll: 'body',
    transitionDuration,
  }), [fullScreen]);

  // Set modal redux state with passed payload object
  const setModal = useCallback(payload => {
    dispatch(modalActions.setModal(payload));
  }, [dispatch]);

  // Clear modal redux property back to inital state
  const clearModal = useCallback(() => {
    // Start fade out of modal immediately
    dispatch(modalActions.closeModal());
    // Clear modal content completely after modal fade out transition complete
    setTimeout(() => dispatch(modalActions.clearModal()), transitionDuration);
  }, [dispatch]);

  return {modal, setModal, clearModal, defaultDialog};
};

export default useUiModal;
