import PropTypes from 'prop-types';
import {Box, Typography} from '@mui/material';

const DataGridHeader = ({children, subtitle, title}) => {
  return (<>
    <Box sx={styles.mainWrapper}>
      <Box sx={styles.titleWrapper}>
        <Typography variant='h5' color='primary' sx={{whiteSpace: 'nowrap'}}>{title}</Typography>
        {subtitle && <Typography color='primary' sx={{whiteSpace: 'nowrap'}}>{subtitle}</Typography>}
      </Box>
      <Box sx={styles.childWrapper} >
        <Box className='dgChildren'>
          {children}
        </Box>
      </Box>
    </Box>
  </>);
};

const styles = {
  mainWrapper: theme => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    overflowX: 'auto',
    padding: '16px',
    [theme.breakpoints.down('sm')]: {padding: '8px'},
  }),
  titleWrapper: theme => ({
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {width: '100%'},
  }),
  childWrapper: theme => ({
    'alignSelf': 'center',
    'whiteSpace': 'nowrap',
    '& .dgChildren': {display: 'flex'},
    '& .dgChildren > *': {marginRight: '6px'},
    '& .dgChildren:last-child': {marginRight: '-6px'},
    [theme.breakpoints.down('sm')]: {
      '& .dgChildren > *': {marginRight: '4px'},
      '& .dgChildren:last-child': {marginRight: '-4px'},
    },
  }),
};
DataGridHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};
export default DataGridHeader;
