import {initializeApp} from 'firebase/app';
import {initializeAppCheck, ReCaptchaV3Provider} from 'firebase/app-check';
import {getAuth, onAuthStateChanged, onIdTokenChanged} from 'firebase/auth';
import {signInWithCustomToken, signOut, updateEmail, updateProfile} from 'firebase/auth';
import {getFirestore, addDoc, arrayUnion, collection, collectionGroup, deleteDoc, doc} from 'firebase/firestore';
import {getDoc, getDocs, query, onSnapshot, orderBy, serverTimestamp, setDoc, updateDoc, where, writeBatch} from 'firebase/firestore';
import {getFunctions, connectFunctionsEmulator, httpsCallable, httpsCallableFromURL} from 'firebase/functions';
import {getRemoteConfig, ensureInitialized, fetchAndActivate, getValue} from 'firebase/remote-config';
import {getStorage, deleteObject, getDownloadURL, getMetadata, updateMetadata, listAll, ref, uploadBytesResumable, uploadBytes} from 'firebase/storage';

const isProductionEnv = process.env.NODE_ENV === 'production';
const FireProd = initializeApp({
  apiKey: 'AIzaSyAQBBpEl0I43qT7dRtP84sef8TBSKwjdJc',
  authDomain: 'kbi-ehs-prod.firebaseapp.com',
  projectId: 'kbi-ehs-prod',
  storageBucket: 'kbi-ehs-prod.appspot.com',
  messagingSenderId: '1003007697759',
  appId: '1:1003007697759:web:6d32eddad7bf39c8604cea',
  measurementId: 'G-KJZBTK9X7J',
}, 'FireProd');
const FireDev = initializeApp({
  apiKey: 'AIzaSyCFi5aYtutgdXo5JFgHSisgbd06a2iTrNU',
  authDomain: 'kbi-ehs-dev.firebaseapp.com',
  projectId: 'kbi-ehs-dev',
  storageBucket: 'kbi-ehs-dev.appspot.com',
  messagingSenderId: '389873285959',
  appId: '1:389873285959:web:fa2fba90a4e41011ceea62',
  measurementId: 'G-463LZ6NV3K',
}, 'FireDev');

// Initialize Firebase App Check (Only for Production Environment)
// https://firebase.google.com/docs/app-check/web/recaptcha-provider
if (isProductionEnv) {
  initializeAppCheck(FireProd, {
    provider: new ReCaptchaV3Provider('6LeDOfojAAAAALf76R2ANN2czZTN9HO_y0jJC3UT'),
    isTokenAutoRefreshEnabled: true,
  });
}

// Initialize Firebase Authentication with conditional override for development environment
const AuthInit = getAuth(isProductionEnv ? FireProd : FireDev);
const Auth = {
  currentUser: () => AuthInit.currentUser,
  onAuthStateChanged: cb => onAuthStateChanged(AuthInit, cb),
  onIdTokenChanged: cb => onIdTokenChanged(AuthInit, cb),
  signInWithCustomToken: customToken => signInWithCustomToken(AuthInit, customToken),
  signOut: () => signOut(AuthInit),
  updateEmail: email => updateEmail(AuthInit.currentUser, email),
  updateProfile: profile => updateProfile(AuthInit.currentUser, profile),
};

// Initialize Firebase Firestore with conditional override for development environment
const FirestoreInit = getFirestore(isProductionEnv ? FireProd : FireDev);
const Firestore = {
  addDoc,
  arrayUnion,
  collection: collectionPath => collection(FirestoreInit, collectionPath),
  collectionGroup: collectionGroupPath => collectionGroup(FirestoreInit, collectionGroupPath),
  deleteDoc,
  doc: param => {
    // When param type is a Firestore collection, passing FirestoreInit will cause system crash (Init already passed to collection);
    if (param?.type === 'collection') return doc(param);
    // Otherewise pass FirestoreInit to properly initialize doc module;
    return doc(FirestoreInit, param);
  },
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  writeBatch: () => writeBatch(FirestoreInit),
};

// Initialize Firebase Functions with conditional override for development environment
const useFunctionEmulator = false;
const FunctionsInit = getFunctions((() => {
  // Always init FireProd when using production environment
  if (isProductionEnv) return FireProd;
  // Init FireProd when using development environment and Firebase Function emulator
  else if (!isProductionEnv && useFunctionEmulator) return FireProd;
  // Init FireDev when using development environment but not Firebase Function emulator
  else if (!isProductionEnv && !useFunctionEmulator) return FireDev;
})());
if (!isProductionEnv && useFunctionEmulator) {
  console.log('Firebase looking for Cloud Functions emulator. To prevent errors, ensure emulator is running or set useFunctionEmulator false.');
  connectFunctionsEmulator(FunctionsInit, 'localhost', 5001);
}
const Functions = {
  httpsCallable: functionName => httpsCallable(FunctionsInit, functionName),
  httpsCallableFromURL: functionUrl => httpsCallableFromURL(FunctionsInit, functionUrl),
};

// Initialize RemoteConfig with conditional override for development environment
const RemoteConfigInit = getRemoteConfig(isProductionEnv ? FireProd : FireDev);
RemoteConfigInit.settings.minimumFetchIntervalMillis = isProductionEnv ? 3600000 : 15000; // One hour : 15 seconds
const RemoteConfig = {
  ensureInitialized: () => ensureInitialized(RemoteConfigInit),
  fetchAndActivate: () => fetchAndActivate(RemoteConfigInit),
  getValue: key => getValue(RemoteConfigInit, key),
};

// Initialize Firebase Storage with conditional override for development environment
const StorageInit = getStorage(isProductionEnv ? FireProd : FireDev);
const Storage = {
  deleteObject,
  getDownloadURL,
  getMetadata,
  listAll,
  ref: refPath => ref(StorageInit, refPath),
  updateMetadata,
  uploadBytesResumable,
  uploadBytes,
};

export {Auth, Firestore, Functions, RemoteConfig, Storage, isProductionEnv};
