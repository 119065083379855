import PropTypes from 'prop-types';
import {Box, Button, Typography} from '@mui/material';
import {StyledPaper} from 'components';
import {SadComputer} from 'icons';

const TableError = ({title}) => {
  const handleContact = () => {
    window.location.href = 'mailto:daniel@kbirecycling.com?Subject=Persisting%20Error&Body=Describe%20Error%20Below:%0D%0A';
  };
  return (
    <StyledPaper>
      {title && <Typography variant='h5' color='primary'>{title}</Typography>}
      <Box sx={styles.bodyWrapper}>
        <SadComputer color='secondary' sx={styles.icon} />
        <Typography color='primary' gutterBottom variant='h6' sx={styles.text}>
            Oops, something went wrong when loading table. Sorry!
        </Typography>
        <Button color='secondary' onClick={handleContact} variant='outlined'>
            Contact us if the problem persists
        </Button>
      </Box>
    </StyledPaper>
  );
};

const styles = {
  bodyWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '24px',
  },
  text: {
    textAlign: 'center',
  },
  icon: {
    fontSize: '10rem',
  },
};
TableError.propTypes = {
  title: PropTypes.string,
};
export default TableError;
