import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Auth, Firestore} from 'firebaseConfig';
import {safetyDataSheetSliceActions} from 'state/safetyDataSheets/slice';
const queryOptions = {includeMetadataChanges: true};

const useSafetyDataSheets = () => {
  const safetyDataSheet = useSelector(state => state.safetyDataSheet);
  const dispatch = useDispatch();
  const duplicateRender = useRef(false);

  useEffect(() => {
    // Prevent duplicate renders caused by React 18 strict mode
    if (duplicateRender.current) return;
    duplicateRender.current = true;

    // Only run remainder of code if hook untouched; Prevents duplicate invocations;
    if (safetyDataSheet.touched) return;
    dispatch(safetyDataSheetSliceActions.setTouched(true));

    let unsubscribe = null;
    // Create functions to get state or forget state depending on user's auth status
    const getState = () => {
      const query = Firestore.collection('Safety-Data-Sheets');
      const snapData = snap => {
        // Never use a snapshot fromCache or that hasPendingWrites (Data may be corrupt); Use pure data from server only;
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const data = [];
        const map = {};
        // If snap is empty and redux state is pending, then set pending to false to show first snap performed
        if (snap.empty && safetyDataSheet.pending) {
          dispatch(safetyDataSheetSliceActions.setData(data));
          dispatch(safetyDataSheetSliceActions.setMap(map));
          dispatch(safetyDataSheetSliceActions.setPending(false));
          return;
        }

        snap.forEach(doc => {
          const docData = doc.data();
          const {EffectiveDate, LastReviewed} = docData;
          const cleanData = {
            ...docData,
            DocId: doc.id,
            EffectiveDate: EffectiveDate?.toMillis ? EffectiveDate.toMillis() : null,
            HazIdentification: {
              ...docData.HazIdentification,
              P65Evaluated: docData?.HazIdentification?.P65Evaluated?.toMillis ? docData.HazIdentification.P65Evaluated.toMillis() : null,
            },
            LastReviewed: LastReviewed?.toMillis ? LastReviewed.toMillis() : null,
            System: {
              ...docData.System,
              EnteredOn: docData.System.EnteredOn.toMillis(),
            },
          };
          data.push(cleanData);
          map[doc.id] = {...cleanData};
        });

        dispatch(safetyDataSheetSliceActions.setData(data));
        dispatch(safetyDataSheetSliceActions.setMap(map));
        dispatch(safetyDataSheetSliceActions.setPending(false));
      };
      const snapError = error => {
        alert(error.message);
        console.log('hooks/state/useSafetyDataSheets Error: ', error.message, {error});
      };
      unsubscribe = Firestore.onSnapshot(query, queryOptions, snapData, snapError);
    };
    const forgetState = () => {
      dispatch(safetyDataSheetSliceActions.forgetState());
      if (unsubscribe) unsubscribe();
    };

    // Make sure user is authenticated before invoking listener
    Auth.onAuthStateChanged(user => {
      if (user) getState();
      else forgetState();
    });
  }, [dispatch, safetyDataSheet]);

  return {
    data: safetyDataSheet.data,
    pending: safetyDataSheet.pending,
  };
};

export default useSafetyDataSheets;
