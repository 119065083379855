import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
  autoHideDuration: 4000,
  message: '',
  open: false,
  severity: 'info',
  transitionDuration: 500,
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setSnackbar: (state, action) => {
      state.anchorOrigin = action?.payload?.anchorOrigin || state.anchorOrigin;
      state.autoHideDuration = action?.payload?.autoHideDuration || state.autoHideDuration;
      state.message = action?.payload?.message || state.message;
      state.open = action?.payload?.open || state.open;
      state.severity = action?.payload?.severity || state.severity;
      state.transitionDuration = action?.payload?.transitionDuration || state.transitionDuration;
    },
    closeSnackbar: (state, action) => {
      state.open = false;
    },
    clearSnackbar: (state, action) => {
      state.anchorOrigin = initialState.anchorOrigin;
      state.autoHideDuration = initialState.autoHideDuration;
      state.message = initialState.message;
      state.open = initialState.open;
      state.severity = initialState.severity;
      state.transitionDuration = initialState.transitionDuration;
    },
  },
});

export const snackbarActions = snackbarSlice.actions;

export default snackbarSlice.reducer;
