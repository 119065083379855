import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {snackbarActions} from 'state/ui/slices/snackbar';

const useUiSnackbar = () => {
  const snackbar = useSelector(state => state.ui.snackbar);
  const dispatch = useDispatch();

  // Set snackbar redux state with passed payload object
  const setSnackbar = useCallback(payload => {
    dispatch(snackbarActions.setSnackbar(payload));
  }, [dispatch]);

  // Clear snackbar redux property back to inital state
  const clearSnackbar = useCallback(() => {
    dispatch(snackbarActions.closeSnackbar());
    setTimeout(() => dispatch(snackbarActions.clearSnackbar()), snackbar.transitionDuration);
  }, [dispatch, snackbar.transitionDuration]);

  return {snackbar, setSnackbar, clearSnackbar};
};

export default useUiSnackbar;
