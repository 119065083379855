import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import {LicenseInfo} from '@mui/x-license';
import reportWebVitals from './reportWebVitals';
import {BuildVersionCheck} from 'components';

// Import Redux Elements
import {Provider as ReduxProvider} from 'react-redux';
import store from './state/store';

// Import Router Elements
import {BrowserRouter} from 'react-router';

// Import Design Elements
import {CssBaseline} from '@mui/material';
import {ThemeProvider, createTheme} from '@mui/material/styles';
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#6D6E71',
      light: '#9b9c9f',
      dark: '#424346',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ed1c24',
      light: '#ff5f4e',
      dark: '#b20000',
      contrastText: '#ffffff',
    },
    background: {
      paper: '#ffffff',
      default: '#eeeeee',
    },
  },
  shape: {borderRadius: 4},
  zIndex: {appBar: 1201},
});

// Add license for MUI Datagrid; Added at root app component to cover all uses in child components
LicenseInfo.setLicenseKey('9b19f872587624f104690f9ecdfc3b42Tz04NDU2NyxFPTE3Mzk4MjE5MDMwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

// Lazy load primary app component to keep main bundle size as small as possible
const App = React.lazy(() => import('./App'));

const root = createRoot(document.getElementById('app'));
root.render(
  <React.StrictMode>
    <CssBaseline />
    <BuildVersionCheck />
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <Suspense fallback={<div />}>
            <App />
          </Suspense>
        </BrowserRouter>
      </ReduxProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
