import {useCallback} from 'react';
import PropTypes from 'prop-types';
import {List, ListItem, TextField} from '@mui/material';

const DetailsFieldList = props => {
  // Default Props
  const fields = props?.fields || [];

  const textFieldProps = useCallback((field) => ({
    fullWidth: true,
    inputProps: {
      sx: {
        cursor: field.link ? 'pointer' : 'default',
        color: field.link ? '#ed1c24' : 'inherit',
        pointerEvents: field.link ? 'auto' : 'none',
      },
    },
    InputProps: {
      readOnly: true,
      sx: {
        backgroundColor: 'white',
        cursor: field.link ? 'pointer' : 'default',
        fontSize: '.9rem',
        pointerEvents: field.link ? 'auto' : 'none',
      },
    },
    label: field.label,
    size: 'small',
    multiline: true,
    onClick: field.onClick ? e => field.onClick(e) : () => {},
    value: '' + field.value, // converts whatever to a string
    variant: 'outlined',
    ...field.textFieldProps,
  }), []);

  if (fields.length === 0) return null;
  return (
    <List sx={style}>
      {fields.map((field, index) => {
        const {visible = true} = field;
        return !visible ? null : <ListItem key={`field${index}`}><TextField {...textFieldProps(field)} /></ListItem>;
      })}
    </List>
  );
};

const style = {
  backgroundColor: '#fef1f2',
  border: '1px solid darkgray',
  width: '100%',
};
DetailsFieldList.propTypes = {
  /** An array of objects mapped over for each of the sections in the list */
  fields: PropTypes.arrayOf(PropTypes.shape({
  /** The label for the field */
    label: PropTypes.string.isRequired,
    /** The value for the field */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    /** This boolean will change the field to act like a link. */
    link: PropTypes.bool,
    /** The click handler for the field */
    onClick: PropTypes.func,
    /** A boolean to hide or show the field */
    visable: PropTypes.bool,
    /** any field specific props to pass to the TextField component */
    textFieldProps: PropTypes.object,
  })).isRequired,
};
export default DetailsFieldList;
