import {configureStore} from '@reduxjs/toolkit';

// Import redux reducers
import AirPollutionReducers from './airPollution/sliceReducer';
import AuthReducers from './auth/sliceReducer';
import InspectionsReducer from './inspections/slice';
import SafetyDataSheetReducer from './safetyDataSheets/slice';
import UiReducers from './ui/sliceReducer';
import WastewaterReducer from './wastewater/slice';

// Initialize Redux Store
const store = configureStore({
  reducer: {
    airPollution: AirPollutionReducers,
    auth: AuthReducers,
    inspections: InspectionsReducer,
    safetyDataSheet: SafetyDataSheetReducer,
    ui: UiReducers,
    wastewater: WastewaterReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    // Turn off serializable check in develepment mode to prevent console warning and slowdown when state is very large
    // https://redux-toolkit.js.org/api/serializabilityMiddleware
    serializableCheck: {
      ignoredActions: ['modal/setModal'],
      ignoredPaths: ['ui.modal.item'],
    },
  }),
});

export default store;
