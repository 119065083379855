import {Button} from '@mui/material';
import PropTypes from 'prop-types';
import {Download} from 'icons';

const DataGridExportButton = props => {
  const apiRef = props?.apiRef || {};
  const buttonText = props?.buttonText || 'Export Table';
  const fileName = props?.fileName || 'Table_Export';
  const startIcon = props?.startIcon || <Download />;

  return (
    <Button color='secondary' variant='outlined' startIcon={startIcon} onClick={() => {
      if (apiRef?.current?.exportDataAsCsv) apiRef.current.exportDataAsCsv({fileName});
      else alert(`Try again. Export functionality was not ready.`);
    }}>
      {buttonText}
    </Button>
  );
};

DataGridExportButton.propTypes = {
  apiRef: PropTypes.object,
  buttonText: PropTypes.string,
  fileName: PropTypes.string,
  startIcon: PropTypes.node,
};
export default DataGridExportButton;
