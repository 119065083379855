import {useDispatch, useSelector} from 'react-redux';
import {sdsViewActions} from 'state/ui/slices/sdsView';

const useUiSdsView = () => {
  const dispatch = useDispatch();
  const {Manufacturer, HazIdentification, Ingredients, Documents, Images, Reviews} = useSelector(state => state.ui.sdsView);

  const setSdsView = payload => dispatch(sdsViewActions.setSdsView(payload));

  return {setSdsView, Manufacturer, HazIdentification, Ingredients, Documents, Images, Reviews};
};

export default useUiSdsView;
