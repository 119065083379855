import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  all: [],
  lastLog: {},
  yearlySummary: [],
  monthlySummary: [],
  dailySummary: [],
  pending: true,
  touched: false,
};

const herboldSlice = createSlice({
  name: 'herbold',
  initialState,
  reducers: {
    setAll: (state, action) => {
      state.all = action.payload;
    },
    setLastLog: (state, action) => {
      state.lastLog = action.payload;
    },
    setYearlySummary: (state, action) => {
      state.yearlySummary = action.payload;
    },
    setMonthlySummary: (state, action) => {
      state.monthlySummary = action.payload;
    },
    setDailySummary: (state, action) => {
      state.dailySummary = action.payload;
    },
    setPending: (state, action) => {
      state.pending = action.payload;
    },
    setTouched: (state, action) => {
      state.touched = action.payload;
    },
    forgetState: (state, action) => {
      state.all = initialState.all;
      state.lastLog = initialState.lastLog;
      state.yearlySummary = initialState.yearlySummary;
      state.monthlySummary = initialState.monthlySummary;
      state.pending = initialState.pending;
      state.touched = initialState.touched;
    },
  },
});

export const herboldActions = herboldSlice.actions;

export default herboldSlice.reducer;
