import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  item: null,
  open: false,
  stage: 'initial',
  target: '',
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModal: (state, {payload}) => {
      state.item = payload?.item || state.item;
      state.open = payload?.open || state.open;
      state.stage = payload?.stage || state.stage;
      state.target = payload?.target || state.target;
    },
    closeModal: (state, action) => {
      state.open = false;
    },
    clearModal: (state, action) => {
      state.item = initialState.item;
      state.open = initialState.open;
      state.stage = initialState.stage;
      state.target = initialState.target;
    },
  },
});

export const modalActions = modalSlice.actions;

export default modalSlice.reducer;
