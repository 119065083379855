import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Auth, Firestore} from 'firebaseConfig';
import {inspectionsActions} from 'state/inspections/slice';
const queryOptions = {includeMetadataChanges: true};

const useInspections = () => {
  const inspections = useSelector(state => state.inspections);
  const dispatch = useDispatch();

  useEffect(() => {
    // Only run remainder of code if hook untouched; Prevents duplicate invocations;
    if (inspections.touched) return;
    dispatch(inspectionsActions.setTouched(true));

    let unsubscribe = null;
    // Create functions to get state or forget state depending on user's auth status
    const getState = () => {
      const query = Firestore.collection('Inspections');
      const snapData = snap => {
        // Never use a snapshot fromCache or that hasPendingWrites (Data may be corrupt); Use pure data from server only;
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const data = [];
        // If snap is empty and redux state is pending, then set pending to false to show first snap performed
        if (snap.empty && inspections.pending) {
          dispatch(inspectionsActions.setData(data));
          dispatch(inspectionsActions.setPending(false));
          return;
        }

        snap.forEach(doc => {
          const docData = doc.data();
          const cleanData = {
            ...docData,
            DocId: doc.id,
            Date: docData?.Date?.toMillis ? docData.Date.toMillis() : null,
            System: {
              ...docData.System,
              EnteredOn: docData.System.EnteredOn.toMillis(),
            },
          };
          data.push(cleanData);
        });

        dispatch(inspectionsActions.setData(data));
        dispatch(inspectionsActions.setPending(false));
      };
      const snapError = error => {
        alert(error.message);
        console.log('hooks/state/useInspections Error: ', error.message, {error});
      };
      unsubscribe = Firestore.onSnapshot(query, queryOptions, snapData, snapError);
    };
    const forgetState = () => {
      dispatch(inspectionsActions.forgetState());
      if (unsubscribe) unsubscribe();
    };

    // Make sure user is authenticated before invoking listener
    Auth.onAuthStateChanged(user => {
      if (user) getState();
      else forgetState();
    });
  }, [dispatch, inspections]);

  return {
    data: inspections.data,
    pending: inspections.pending,
  };
};

export default useInspections;
