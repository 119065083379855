import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button, IconButton, Typography} from '@mui/material';
import {Close, SadComputer} from 'icons';
import {Auth, Firestore, isProductionEnv} from 'firebaseConfig';
import StyledPaper from './StyledPaper';

class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    if (isProductionEnv) {
      (async () => {
        try {
          const currentUser = Auth.currentUser();
          const errorItem = {
            to: 'daniel@kbirecycling.com',
            TTL: new Date(new Date().setDate(new Date().getDate() + 30)),
            template: {
              name: 'SystemError',
              data: {
                userName: currentUser?.displayName || 'Missing',
                userEmail: currentUser?.email || 'Missing',
                timestamp: new Date().toLocaleDateString(),
                errorMessage: error?.message || 'Missing',
                errorStack: error?.stack || 'Missing',
                componentStack: errorInfo?.componentStack || 'Missing',
              },
            },
          };
          console.log({errorItem});
          await Firestore.addDoc(Firestore.collection('Emails'), errorItem);
        }
        catch (error) {
          alert(`Failed to notify web developers: ${error.message}`);
        }
      })();
    }
    else {
      console.log('Dev Environment AppErrorBoundary', {error, errorInfo});
    }
  }

  render() {
    const handleContact = () => {
      window.location.href = 'mailto:daniel@kbirecycling.com?Subject=Persisting%20Error&Body=Describe%20Error%20Below:%0D%0A';
    };
    const handleClose = () => {
      // Naviagte use back to App route with refresh
      window.location.replace('/');
    };

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box sx={styles.wrapper}>
          <StyledPaper sx={styles.paper}>
            <Typography sx={styles.header} variant='h5'>
              Oops, something went wrong. Sorry!
            </Typography>
            <SadComputer color='secondary' sx={styles.icon} />
            <Typography sx={styles.message} variant='subtitle1'>
              Our team has been notified and will be looking into the issue as soon as possible.
            </Typography>
            <Button color='secondary' onClick={handleContact} variant='outlined'>
              Contact us if the problem persists
            </Button>
            <IconButton onClick={handleClose} sx={styles.close}>
              <Close />
            </IconButton>
          </StyledPaper>
        </Box>
      );
    }

    return this.props.children;
  }
}

const styles = {
  wrapper: {
    alignItems: 'center',
    backgroundColor: 'lightgray',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
  },
  paper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 80px',
    position: 'relative',
  },
  header: {
    fontWeight: 500,
    marginBottom: '24px',
  },
  icon: {
    fontSize: '10em',
    marginBottom: '16px',
  },
  message: {
    fontWeight: 500,
    marginBottom: '24px',
    marginTop: '8px',
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
};
AppErrorBoundary.propTypes = {
  children: PropTypes.oneOfType(
    [PropTypes.arrayOf(PropTypes.node), PropTypes.node]
  ).isRequired,
};
export default AppErrorBoundary;
